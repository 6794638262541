<template>
  <div
    class="phone-dialogue"
    :class="$store.state.mobileView ? 'mobile' : 'desktop'"
  >
    <div class="reward-modal" v-if="$store.state.showRewardModal">
      <div class="reward-container">
        <div class="title-container">
          You've Earned the<br />
          {{ reward.award }} Award!
        </div>
        <div
          class="reward-images"
          :class="$store.state.roundCounter % 2 === 1 ? 'left' : 'right'"
          :style="{
            backgroundImage:
              'url(' + require('@/assets/rewards/' + reward.athlete) + ')',
          }"
        >
          <img
            class="reward-image"
            :src="require('../assets/rewards/' + reward.image)"
          />
        </div>
        <button
          v-on:click="goToRoundProgress"
          class="reward-button red-buttons"
        >
          Next
        </button>
      </div>
    </div>
    <div class="top-bar">
      <Hud />
    </div>
    <div class="bottom-bar">
      <!-- Questions and Answers on Left Side -->
      <div
        v-if="
          !$store.state.showRoundFeedback &&
          $store.state.showQuestion &&
          !$store.state.mobileView
        "
        class="question-container"
      >
        <div
          class="question-bobby"
          v-if="!$store.state.showRoundFeedback && !$store.state.mobileView"
        >
          <img src="../assets/characters/bobby-the-badger-avatar-big.png" />
          <Questions />
        </div>
        <div>
          <Answers />
        </div>
      </div>
      <div
        v-if="$store.state.showRoundFeedback && !$store.state.mobileView"
        class="round-feedback"
      >
        <div class="feedback-image"></div>
        <div class="round-feedback-right">
          <div class="round-feedback-text">
            <div>Nice Job! You scored</div>
            <div>{{ $store.state.roundScore + " points" }}</div>
            <div>Keep hustling!</div>
          </div>
          <button class="red-buttons" v-on:click="showReward">
            {{ $store.state.nextButtonText }}
          </button>
        </div>
      </div>
      <div
        id="next-setup"
        class="conversation-component"
        tabindex="0"
        v-on:click="nextSetup($event.target)"
        :class="$store.state.mobileView ? 'mobile' : 'desktop'"
      >
        <div id="container" class="conversation-container">
          <div v-if="!$store.state.mobileView" class="phone-top">
            <div class="speaker"></div>
            <div class="camera"></div>
          </div>
          <div class="avatar-container">
            <div
              class="character"
              v-for="character in avatarData"
              :key="character.id"
            >
              <img
                class="avatar"
                :src="
                  require('../assets/characters/' +
                    character.image +
                    '-avatar-big.png')
                "
                :alt="character.image"
              />
              <div class="character-name">
                {{ character.text.split("-")[0] }}
              </div>
            </div>
          </div>
          <div class="message-container">
            <img class="fade" src="@/assets/fadetransparent.png" />
            <div id="messages" class="messages">
              <div
                class="message"
                v-for="setup in setupData"
                :key="setup.id"
                :class="
                  setup.character === 2 ? 'main-character' : 'other-characters'
                "
              >
                <div v-if="setup.character">
                  <img
                    class="message-avatar"
                    :src="
                      require('../assets/characters/' +
                        characterData['character-' + setup.character].image +
                        '-avatar-big' +
                        (setup.characteremotion
                          ? '-' + setup.characteremotion
                          : '') +
                        '.png')
                    "
                    :alt="
                      (setup.characteremotion ? setup.characteremotion : '') +
                      ' ' +
                      characterData['character-' + setup.character].image
                    "
                  />
                </div>
                <div
                  class="character-message"
                  :class="[
                    setup.character
                      ? 'character' + setup.character
                      : 'narrator',
                    setup.character === 2
                      ? 'main-character right-pop'
                      : 'other-characters left-pop',
                  ]"
                >
                  <input
                    class="audio"
                    type="image"
                    v-on:click="$store.dispatch('playVO', setup.id)"
                    :src="require('../assets/play.png')"
                    alt="play sound"
                  />
                  {{ setup.text }}
                </div>
              </div>
              <!-- Questions and Answers in Message Div -->
              <div
                v-if="$store.state.showQuestion && $store.state.mobileView"
                class="question-container center-pop"
              >
                <div>
                  <div class="question-text">
                    <img
                      src="../assets/characters/bobby-the-badger-avatar-big.png"
                    />
                    <Questions />
                  </div>
                  <div v-if="$store.state.showQuestion">
                    <Answers />
                  </div>
                </div>
              </div>
              <div v-if="$store.state.showResults">
                <!-- add selected answer and selected results -->
                <div
                  class="message"
                  v-for="result in resultData"
                  :key="result.id"
                  :class="
                    result.character === 2
                      ? 'main-character right-pop'
                      : 'other-characters left-pop'
                  "
                >
                  <div v-if="result.character">
                    <img
                      class="message-avatar"
                      :src="
                        require('../assets/characters/' +
                          characterData['character-' + result.character].image +
                          '-avatar-big' +
                          (result.characteremotion
                            ? '-' + result.characteremotion
                            : '') +
                          '.png')
                      "
                      :alt="
                        characterData['character-' + result.character].image
                      "
                    />
                  </div>
                  <div
                    class="character-message"
                    :class="
                      result.character
                        ? 'character' + result.character
                        : 'narrator'
                    "
                  >
                    <input
                      class="audio"
                      type="image"
                      v-on:click="$store.dispatch('playVO', result.id)"
                      :src="require('../assets/play.png')"
                      alt="play sound"
                    />
                    {{ result.text }}
                  </div>
                </div>
                <div
                  v-if="
                    $store.state.showFeedback &&
                    $store.state.mode === 'textmessage' &&
                    $store.state.mobileView
                  "
                >
                  <div class="question-container center-pop">
                    <div>
                      <div class="question-text">
                        <img
                          src="../assets/characters/bobby-the-badger-avatar-big.png"
                        />
                        <Feedback />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="$store.state.showRoundFeedback && $store.state.mobileView"
                class="round-feedback"
              >
                <div class="feedback-image"></div>
                <div class="round-feedback-right">
                  <div class="round-feedback-text">
                    <div>Nice Job! You scored</div>
                    <div>{{ $store.state.roundScore + " points" }}</div>
                    <div>Keep hustling!</div>
                  </div>
                  <button class="red-buttons" v-on:click="showReward">
                    {{ $store.state.nextButtonText }}
                  </button>
                </div>
              </div>
              <div
                v-if="
                  $store.state.mobileView &&
                  $store.state.showFeedback &&
                  (!$store.state.currentQuestionType === 'resulting-response' ||
                    ($store.state.currentQuestionType ===
                      'resulting-response' &&
                      $store.state.decisionCounter <
                        Object.keys(
                          $store.state.content[
                            'round' + $store.state.roundCounter
                          ]
                        ).length -
                          1)) &&
                  !this.$store.state.showRoundFeedback
                "
                class="nextButton"
              >
                <button
                  class="yellow-buttons"
                  :class="$store.state.mobileView ? 'mobile' : 'desktop'"
                  v-on:click="nextQuestion"
                >
                  {{ $store.state.nextButtonText }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Answers from "@/components/Answers.vue";
import Hud from "@/components/Hud.vue";
import Questions from "@/components/Questions.vue";
import Feedback from "@/components/Feedback.vue";
import $ from "jquery";
export default {
  name: "Conversation",
  components: {
    Answers,
    Feedback,
    Hud,
    Questions,
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.commit("getFocusableItems");
    // autoplay first audio file
    if (this.$store.state.audio) {
      this.$store.dispatch(
        "playVO",
        this.$store.state.roundCounter +
          "-" +
          (this.$store.state.showQuestion ? "question" : "setup") +
          "-" +
          this.$store.state.decisionCounter +
          (this.$store.state.showQuestion
            ? ""
            : "-" + this.$store.state.setupCounter)
      );
    }
  },
  methods: {
    nextSetup(e) {
      $(window).trigger("resize");
      if (
        !$(e).hasClass("choices") &&
        !$(e).hasClass("choice-buttons") &&
        !$(e).hasClass("yellow-buttons") &&
        !$(e).hasClass("red-buttons") &&
        !$(e).hasClass("audio") &&
        !this.$store.state.showRoundFeedback &&
        document.getElementById("feedback-next-false") === null
      ) {
        this.$store.commit("stopAllSounds");
        if (
          this.$store.state.content["round" + this.$store.state.roundCounter][
            "decision" + this.$store.state.decisionCounter
          ].setup &&
          this.$store.state.setupCounter ===
            Object.keys(
              this.$store.state.content[
                "round" + this.$store.state.roundCounter
              ]["decision" + this.$store.state.decisionCounter].setup
            ).length &&
          !this.$store.state.showResults
        ) {
          this.$store.commit("changeQuestionState", true);
        } else if (this.$store.state.showResults) {
          this.$store.state.resultCounter += 1;
          if (
            this.$store.state.decisionCounter ===
              Object.keys(
                this.$store.state.content[
                  "round" + this.$store.state.roundCounter
                ]
              ).length -
                1 &&
            this.$store.state.resultCounter >=
              Object.keys(this.$store.state.results).length
          ) {
            if (this.$store.state.showFeedback) {
              this.$store.state.showRoundFeedback = true;
            }
          }
        } else {
          this.$store.commit("updateCurrent");
          this.$store.state.setupCounter += 1;
        }
        // autoplay next audio
        if (this.$store.state.audio && !this.$store.state.showResults) {
          this.$store.dispatch(
            "playVO",
            this.$store.state.roundCounter +
              "-" +
              (this.$store.state.showQuestion ? "question" : "setup") +
              "-" +
              this.$store.state.decisionCounter +
              (this.$store.state.showQuestion
                ? ""
                : "-" + this.$store.state.setupCounter)
          );
        } else if (this.$store.state.audio && this.$store.state.showResults) {
          const choiceNum = this.$store.state.feedback.id.split("-")[3];
          if (
            this.$store.state.resultCounter <
            Object.keys(this.$store.state.results).length + 1
          ) {
            const result =
              this.$store.state.roundCounter +
              "-result-" +
              this.$store.state.decisionCounter +
              "-" +
              choiceNum +
              "-" +
              (this.$store.state.resultCounter - 1);
            this.$store.dispatch("playVO", result);
          }
        }
      }
    },
    nextQuestion() {
      this.$store.commit("stopAllSounds");
      this.$store.state.showRewardModal = false;
      this.$store.state.showQuestion = false;
      this.$store.state.showFeedback = false;
      this.$store.state.showResults = false;
      this.$store.state.correct = false;
      this.$store.state.setupCounter = 1;
      this.$store.state.resultCounter = 1;
      this.$store.state.results = {};
      //  check for end of question
      if (
        this.$store.state.decisionCounter ===
        Object.keys(
          this.$store.state.content["round" + this.$store.state.roundCounter]
        ).length -
          1
      ) {
        // check for end of rounds
        if (
          this.$store.state.roundCounter <
          Object.keys(this.$store.state.content).length
        ) {
          this.nextRound();
        } else {
          this.$store.commit("navigate", { route: "Outcome", data: "" });
        }
      } else {
        this.$store.state.decisionCounter += 1;
      }
      if (
        !this.$store.state.content["round" + this.$store.state.roundCounter][
          "decision" + this.$store.state.decisionCounter
        ].setup
      ) {
        this.$store.commit("changeQuestionState", true);
      }
      this.$store.commit("updateCurrent");
      // autoplay next audio
      if (this.$store.state.audio) {
        this.$store.dispatch(
          "playVO",
          this.$store.state.roundCounter +
            "-" +
            (this.$store.state.showQuestion ? "question" : "setup") +
            "-" +
            this.$store.state.decisionCounter +
            (this.$store.state.showQuestion
              ? ""
              : "-" + this.$store.state.setupCounter)
        );
      }
      this.$store.commit(
        "setProgress",
        ((this.$store.state.decisionCounter - 1) /
          (Object.keys(
            this.$store.state.content["round" + this.$store.state.roundCounter]
          ).length -
            1)) *
          100
      );
    },
    nextRound() {
      this.$store.commit("stopAllSounds");
      this.$store.state.showRoundFeedback = false;
      this.$store.state.roundCounter += 1;
      this.$store.state.decisionCounter = 1;
      this.$store.state.setupCounter = 1;
      this.$store.state.resultCounter = 1;
      this.$store.state.roundScore = 0;
      this.$store.state.showFeedback = false;
      this.$store.state.results = {};
      this.$store.state.correct = false;
      this.$store.state.showResults = false;
      this.$store.state.showQuestion = false;
      this.$store.state.nextButtonText = "Next Question";
      window.dispatchEvent(new Event("resize"));
      if (
        this.$store.state.currentRoute === "Dialogue" &&
        this.$store.state.content["round" + this.$store.state.roundCounter].info
          .title.interaction === "in person"
      ) {
        this.$store.state.mode = "inperson";
      } else if (
        this.$store.state.currentRoute === "Dialogue" &&
        this.$store.state.content["round" + this.$store.state.roundCounter].info
          .title.interaction === "text message"
      ) {
        this.$store.state.mode = "textmessage";
      } else {
        this.$store.state.mode = "postit";
      }
    },
    showReward() {
      this.$store.commit("stopAllSounds");
      this.$store.state.showRewardModal = true;
      if (this.$store.state.audio) {
        this.$store.dispatch("playVO", this.reward.audio);
      }
    },
    goToRoundProgress() {
      window.dispatchEvent(new Event("resize"));
      this.$store.commit("stopAllSounds");
      this.$store.state.showRoundFeedback = false;
      this.$store.state.roundCounter += 1;
      this.$store.state.decisionCounter = 1;
      this.$store.state.setupCounter = 1;
      this.$store.state.resultCounter = 1;
      this.$store.state.roundScore = 0;
      this.$store.state.nextButtonText = "Next Question";
      this.$store.state.showRewardModal = false;
      this.$store.state.showQuestion = false;
      this.$store.state.showFeedback = false;
      this.$store.state.showResults = false;
      this.$store.state.correct = false;
      this.$store.state.results = {};
      this.$store.commit("setProgress", 0);
      if (
        this.$store.state.currentRoute === "Dialogue" &&
        this.$store.state.content["round" + this.$store.state.roundCounter].info
          .title.interaction === "in person"
      ) {
        this.$store.state.mode = "inperson";
      } else if (
        this.$store.state.currentRoute === "Dialogue" &&
        this.$store.state.content["round" + this.$store.state.roundCounter].info
          .title.interaction === "text message"
      ) {
        this.$store.state.mode = "textmessage";
      } else {
        this.$store.state.mode = "postit";
      }
      this.$store.commit("navigate", { route: "RoadMap", data: "" });
    },
  },
  computed: {
    setupData: function () {
      let visibleSetups = {};
      if (
        this.$store.state.content["round" + this.$store.state.roundCounter][
          "decision" + this.$store.state.decisionCounter
        ].setup
      ) {
        for (let [key, value] of Object.entries(
          this.$store.state.content["round" + this.$store.state.roundCounter][
            "decision" + this.$store.state.decisionCounter
          ].setup
        )) {
          if (key.split("-")[3] <= this.$store.state.setupCounter) {
            visibleSetups[key] = value;
          }
        }
      }
      return visibleSetups;
    },
    resultData: function () {
      let visibleResults = {};
      for (const index in Object.keys(this.$store.state.results)) {
        const key = Object.keys(this.$store.state.results)[index];
        if (index < this.$store.state.resultCounter) {
          visibleResults[key] = this.$store.state.results[key];
        }
      }
      if (
        this.$store.state.resultCounter ===
        Object.keys(this.$store.state.results).length + 1
      ) {
        this.$store.commit("updateFeedbackState");

        if (this.$store.state.audio) {
          this.$store.dispatch("playVO", this.$store.state.feedback.id);
        }
      }
      return visibleResults;
    },
    characterData: function () {
      let characters =
        this.$store.state.content["round" + this.$store.state.roundCounter].info
          .characters;
      return characters;
    },
    avatarData: function () {
      let avatars = {};
      Object.assign(avatars, this.characterData);
      delete avatars["character-4"];
      return avatars;
    },
    reward: function () {
      let reward = {};
      reward["athlete"] =
        this.$store.state.content["round" + this.$store.state.roundCounter].info
          .title.image + "-athlete.png";
      reward["image"] =
        this.$store.state.content["round" + this.$store.state.roundCounter].info
          .title.image + ".png";
      reward["award"] =
        this.$store.state.rewards[
          this.$store.state.content[
            "round" + this.$store.state.roundCounter
          ].info.title.image
        ];
      reward["audio"] =
        this.$store.state.content["round" + this.$store.state.roundCounter].info
          .title.image + "-award";
      return reward;
    },
  },
  updated() {
    this.$store.commit("getFocusableItems");
    if (
      this.$store.state.content["round" + this.$store.state.roundCounter][
        "decision" + this.$store.state.decisionCounter
      ].setup
    ) {
      this.$store.commit("scrollToEnd", "messages");
    }
  },
};
</script>

<style scoped>
.phone-dialogue {
  display: grid;
  grid-template-rows: minmax(0, 2fr) minmax(0, 7fr);
  grid-template-columns: 4fr minmax(0, 3fr);
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  font-size: clamp(12px, 3vw, 3vh);
}

.phone-dialogue.mobile {
  grid-template-rows: minmax(0, 2fr) minmax(0, 10fr);
}

.top-bar {
  grid-row: 1;
  grid-column-start: 1;
  grid-column-end: 3;
  background-color: var(--top-bar-blue);
}

.bottom-bar {
  display: grid;
  position: relative;
  grid-template-rows: minmax(0, 1fr);
  grid-row: 2;
  grid-column-start: 1;
  grid-column-end: 4;
}

.desktop > * .bottom-bar {
  grid-template-columns: 1fr minmax(0, 15fr) 16fr;
}

.conversation-component {
  font-size: clamp(12px, 2vw, 3vh);
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  background-color: white;
  overflow: hidden;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 2;
  box-sizing: border-box;
  grid-column-end: 3;
}

.conversation-component.desktop {
  position: absolute;
  grid-column-start: 2;
  grid-column-end: 3;
  top: -15vh;
  border-style: solid;
  border-color: var(--dark-blue);
  border-width: 20px 20px 0px 20px;
  border-radius: 50px 50px 0px 0px;
  box-sizing: border-box;
  max-width: 100%;
  height: calc(100% + 15vh);
}

.conversation-component.mobile {
  border-radius: 0px;
  grid-column-start: 1;
  grid-column-end: 3;
  font-size: clamp(12px, 3.5vw, 2vh);
}

.fade {
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 0;
}

.conversation-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 8% 20% 72%;
  height: 100%;
  width: 100%;
}

.mobile > * .conversation-container {
  grid-template-rows: 15% 85%;
}

.character {
  display: grid;
  grid-template-rows: minmax(0, 4fr) 1fr;
  height: 85%;
  font-size: clamp(12px, 1.5vw, 3vh);
  font-variation-settings: "wght" 700;
  align-items: center;
  align-self: center;
  justify-items: center;
}

.mobile > * .character {
  font-size: clamp(12px, 2.25vw, 3vh);
}

.avatar {
  height: 80%;
}

.mobile > * .avatar {
  height: 100%;
}

.avatar-container {
  display: flex;
  flex-direction: row;
  grid-row: 1;
  grid-column-start: 1;
  grid-column-end: 4;
  background-color: var(--teal);
  justify-content: space-evenly;
}

.desktop > * .avatar-container {
  grid-row: 2;
}

.message-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  grid-row: 2;
  grid-column: 1;
  padding: 0px 3vw 3vw 3vw;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.desktop > * .message-container {
  grid-row: 3;
  padding: 0px;
}

.messages {
  justify-content: flex-end;
  text-align: left;
  overflow: auto;
  grid-row: 2;
  grid-column: 2;
}

.messages > :first-child {
  margin-top: 30px;
}

.messages > :last-child {
  margin-bottom: 30px;
}

.message {
  margin: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.message-avatar {
  width: 5vw;
  margin: 1vw;
}

.mobile > * .message-avatar {
  width: 9vw;
}

.question-container {
  background: var(--light-blue);
  margin: 20px;
  border-radius: 30px;
  padding: 20px;
  justify-content: center;
  font-size: clamp(12px, 2vw, 3vh);
}

.desktop > * .question-container {
  grid-column: 3;
}

.question-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.question-text > img {
  height: 6em;
}

.question-bobby {
  display: flex;
}

.question-bobby > img,
.question-container > img {
  align-self: center;
  justify-self: center;
  box-sizing: border-box;
  height: 4em;
  max-height: 135px;
}

.mobile > * .question-bobby > img,
.mobile > * .question-container > img {
  height: 15vw;
}

.desktop > * .question-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr minmax(0, 4fr);
}

.main-character {
  flex-direction: row-reverse;
}

/* Ivy */
.character1 {
  border-radius: 30px;
  background-color: #f0ccff;
  color: #4c4c4c;
  padding: 20px;
}
/* Jordan */
.character2 {
  border-radius: 30px;
  background-color: #c8e07c;
  color: #4c4c4c;
  padding: 20px;
}
/* Felix */
.character3 {
  border-radius: 30px;
  background-color: #fee3ae;
  color: #4c4c4c;
  padding: 20px;
}
/* Bobby the Badger */
.character4 {
  border-radius: 30px;
  background-color: #54647d;
  color: white;
  padding: 20px;
}

.round-feedback {
  position: relative;
  display: grid;
  grid-column: 3;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 1fr minmax(0, 2fr);
  border-radius: 20px;
  justify-content: flex-end;
  align-items: center;
}

.mobile > * .round-feedback {
  grid-template-columns: 40% 60%;
  grid-template-rows: 1fr;
  background-color: var(--light-blue);
}

.round-feedback-text {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 20px;
  background-color: var(--yellow);
  font-variation-settings: "wght" 900;
  align-items: center;
  margin: 1vh;
}

.round-feedback-right {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row: 2;
  align-items: center;
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px 0px;
}

.mobile > * .round-feedback-right {
  grid-column: 2;
  grid-row: 1;
}

.feedback-image {
  grid-row: 3;
  grid-column: 1;
  background-image: url("../assets/characters/mascot-top.png");
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.mobile > * .feedback-image {
  grid-column: 1;
  grid-row: 1;
}

.character-message {
  display: flex;
  align-items: center;
  cursor:pointer;
}

.phone-top {
  background-color: var(--dark-blue);
  display: flex;
  justify-content: center;
  align-items: center;
}
.camera {
  width: 1.5vh;
  height: 1.5vh;
  margin: 0 0 0 2vh;
  background-color: black;
  border-radius: 50%;
}
.speaker {
  width: 20%;
  height: 15%;
  background-color: black;
  border-radius: 10px;
}

.nextButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
